<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h1 class="text-center" style="margin-bottom: 50px;">Recomended Help</h1>
      <div class="row">
        <div class="col-4 menu">
          <h4 style="margin-bottom: 20px;">Recommended Articles</h4>
          <strong>For Buyers</strong>
          <ul class="list-unstyled">
            <li><a href="javascript:;" @click="show(1)" :class="{active: selected_article === 1}">How to create an account?</a></li>
            <li><a href="javascript:;" @click="show(2)" :class="{active: selected_article === 2}">How do I reset or change password?</a></li>
            <li><a href="javascript:;" @click="show(3)" :class="{active: selected_article === 3}">How to create account and save searchers?</a></li>
          </ul>
          <strong>For Sellers</strong>
          <ul class="list-unstyled">
            <li><a href="javascript:;" @click="show(4)" :class="{active: selected_article === 4}">How do I add an advertising?</a></li>
            <li><a href="javascript:;" @click="show(5)" :class="{active: selected_article === 5}">Create seller profile and manage listings?</a></li>
          </ul>
        </div>
        <div class="col-8" v-if="selected_article === 1">
          <strong>How to create an account?</strong>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam cupiditate, distinctio dolores eos id iste itaque molestiae natus nobis, omnis rerum soluta, tenetur voluptates! Aliquam distinctio harum ullam vitae voluptas.</p>
        </div>
        <div class="col-8" v-if="selected_article === 2">
          <strong>How do I reset or change password?</strong>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius eos maiores nihil quam repellat tempore? Ab animi cupiditate doloremque eligendi fugit incidunt ipsa modi non, placeat, praesentium qui sed ullam.</p>
        </div>
        <div class="col-8" v-if="selected_article === 3">
          <strong>How to create account and save searchers?</strong>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur, doloremque exercitationem hic ipsum iste magni maxime minus nostrum recusandae! Blanditiis corporis cupiditate facere id, illo impedit officia recusandae sunt velit.</p>
        </div>
        <div class="col-8" v-if="selected_article === 4">
          <strong>How do I add an advertising?</strong>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam aliquid architecto dolor esse fugiat harum labore libero, molestiae molestias nisi nobis optio provident ratione, reprehenderit rerum saepe sint, ut?</p>
        </div>
        <div class="col-8" v-if="selected_article === 5">
          <strong>Create seller profile and manage listings?</strong>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis eaque eos exercitationem fugit laborum necessitatibus optio quidem quo ut voluptate? Animi ducimus et ex exercitationem, in ipsam nisi perferendis sequi!</p>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  data() {
    return {
      selected_article: 1
    }
  },
  mounted() {
    document.title = 'Recomended Help - Mane Street Market';
    window.scrollTo(0, 0);
  },
  methods: {
    show(articleId) {
      this.selected_article = articleId;
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    border: 1px solid #ddd;
    background: #FAFAFB;
    padding: 30px;
  }

  ul {
    margin-left: 10px;
    li {
      margin-bottom: 10px;

      a {
        text-decoration: none;
        color: #050930;

        &.active {
          color: #0B178A;
          font-weight: bold;
        }
      }
    }
  }

  .col-8 {
    padding-left: 30px;
    padding-top: 30px;
  }
</style>